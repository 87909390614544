import React from 'react';
import { Button } from '@mui/material';
import { useAnalytics } from '../context/AnalyticsContext';
import PropTypes from 'prop-types';

/**
 * Button component that automatically tracks clicks with Fathom analytics
 * @param {Object} props - Component props
 * @param {string} props.eventId - Fathom event ID to track
 * @param {Object} [props.trackingData] - Additional data to track with the event
 * @param {Function} [props.onClick] - Original onClick handler
 * @param {React.ReactNode} props.children - Button content
 * @param {string} [props.eventCategory] - Category for the event (optional)
 */
export const TrackedButton = ({
  eventId,
  trackingData,
  onClick,
  children,
  eventCategory,
  ...buttonProps
}) => {
  const { trackEvent } = useAnalytics();

  const handleClick = (event) => {
    // Track the click
    if(window.fathom){
      window.fathom.trackEvent(trackingData.name);
    }


    // Call the original onClick handler if provided
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Button
      {...buttonProps}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};

TrackedButton.propTypes = {
  eventId: PropTypes.string,
  trackingData: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  eventCategory: PropTypes.string,
};