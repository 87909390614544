import React from 'react';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router";
import {useStatus} from "../providers/MsgStatusProvider";

const LinkedInOrgConnectButton = () => {
    const navigate = useNavigate();
    const { setStatusMessage, setIsAlertOpen, setStatusType } = useStatus();

  const handleConnect = () => {
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_ORG_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LINKEDIN_ORG_REDIRECT_URL}&scope=r_organization_followers%20rw_organization_admin%20r_organization_social%20r_organization_social_feed%20w_member_social%20r_basicprofile%20w_organization_social_feed%20w_member_social_feed%20r_1st_connections_size%20w_organization_social`;
    window.location.href = url
  };

  return (
   <>
        <Button variant="contained" color="primary" onClick={handleConnect}>
            Connect to LinkedIn Organization
          </Button>
    </>
  );
};

export default LinkedInOrgConnectButton;
