import React, {useEffect, useState} from 'react';
import {Navigate, useNavigate, useLocation} from 'react-router-dom';
import {useAuth} from "./AuthProvider";
import {useStatus} from "./MsgStatusProvider";
import {logError} from "../utils/logger";

export const PrivateRoutes = ({children, userDetail}) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [isPaymentNeeded, setIsPaymentNeeded] = useState(false);
    const {setStatusMessage, setStatusType, setIsAlertOpen} = useStatus();

    useEffect(() => {
        if (userDetail !== undefined) {
            setIsLoading(false);
        }
        checkAccountStatus();
    }, [userDetail]);

    const checkAccountStatus = () => {
        // Check payment status
        const subscriptionStatus = userDetail?.userprofile?.subscription?.status;
        if (subscriptionStatus === 'canceled' || subscriptionStatus === 'blocked') {
            setIsPaymentNeeded(true);
        }

        // Check onboarding status
        const isOnboardingComplete = userDetail?.userprofile?.is_onboarding_complete;
        if (isOnboardingComplete === false && location.pathname !== '/onboarding') {
            logError('Incomplete onboarding detected', {
                userId: userDetail?.id,
                currentPath: location.pathname
            });
            navigate('/onboarding');
            setStatusMessage('Please complete your onboarding process');
            setIsAlertOpen(true);
            setStatusType('info');
        }
    };

    useEffect(() => {
        if (!isLoading && auth.isAuthenticated && localStorage.getItem('token')) {
            if (isPaymentNeeded && location.pathname !== '/profile') {
                navigate('/profile');
                setStatusMessage("Your account is missing payment. If you don't believe you should see this message please contact support.");
                setIsAlertOpen(true);
                setStatusType('error');
            }
        }
    }, [auth.isAuthenticated, userDetail, navigate, isLoading, location.pathname]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!auth.isAuthenticated) {
        return <Navigate to='/login' />;
    }

    return children;
};
