import TemplateCreate from "../../components/TemplateCreate";

export const ButtonEvents = {
    // Authentication related buttons
    LOGIN_BUTTON_CLICK: {id: 'BTN001', name: 'Login Button Click', category: 'auth'},
    SIGNUP_BUTTON_CLICK: {id: 'BTN002', name: 'Signup Button Click', category: 'auth'},
    LOGOUT_BUTTON_CLICK: {id: 'BTN003', name: 'Logout Button Click', category: 'auth'},

    // Social media related buttons
    CONNECT_TWITTER_CLICK: {id: 'BTN004', name: 'Connect Twitter Click', category: 'social'},
    CONNECT_LINKEDIN_CLICK: {id: 'BTN005', name: 'Connect LinkedIn Click', category: 'social'},
    POST_CREATE_CLICK: {id: 'BTN006', name: 'Create Post Click', category: 'social'},

    // Template related buttons
    TEMPLATE_CREATE_CLICK: {id: 'BTN007', name: 'Create Template Click', category: 'template'},
    TEMPLATE_EDIT_CLICK: {id: 'BTN008', name: 'Edit Template Click', category: 'template'},
    TEMPLATE_DELETE_CLICK: {id: 'BTN009', name: 'Delete Template Click', category: 'template'},

    // Subscription related buttons
    SUBSCRIBE_CLICK: {id: 'BTN010', name: 'Subscribe Click', category: 'subscription'},
    UPGRADE_PLAN_CLICK: {id: 'BTN011', name: 'Upgrade Plan Click', category: 'subscription'},

    // Navigation buttons
    NAV_DASHBOARD_CLICK: {id: 'BTN012', name: 'Dashboard Nav Click', category: 'navigation'},
    NAV_PROFILE_CLICK: {id: 'BTN013', name: 'Profile Nav Click', category: 'navigation'},
};

export const AnalyticsEvents = {
    // Auth Events
    LOGIN_SUCCESS: {id: 'AUTH001', name: 'Login Success', category: 'auth'},
    LOGIN_FAILURE: {id: 'AUTH002', name: 'Login Failed', category: 'auth'},
    SIGNUP_SUCCESS: {id: 'AUTH003', name: 'Signup Success', category: 'auth'},
    SIGNUP_FAILURE: {id: 'AUTH004', name: 'Signup Failed', category: 'auth'},
    EMAIL_CONFIRMED: {id: 'AUTH005', name: 'Email Confirmed', category: 'auth'},
    PASSWORD_RESET_REQUESTED: {id: 'AUTH006', name: 'Password Reset Requested', category: 'auth'},

    // Social Connection Events
    SOCIAL_CONNECT_SUCCESS: {id: 'SOC001', name: 'Social Account Connected', category: 'social'},
    SOCIAL_CONNECT_FAILURE: {id: 'SOC002', name: 'Social Connection Failed', category: 'social'},

    // Post Events
    POST_CREATED: {id: 'POST001', name: 'Post Created', category: 'content'},
    POST_SCHEDULED: {id: 'POST002', name: 'Post Scheduled', category: 'content'},
    POST_PUBLISHED: {id: 'POST003', name: 'Post Published', category: 'content'},

    // Template Events
    TEMPLATE_CREATED: {id: 'TEMP001', name: 'Template Created', category: 'content'},
    TEMPLATE_EDITED: {id: 'TEMP002', name: 'Template Edited', category: 'content'},

    // Onboarding Events
    ONBOARDING_STARTED: {id: 'ONB001', name: 'Onboarding Started', category: 'onboarding'},
    ONBOARDING_COMPLETED: {id: 'ONB002', name: 'Onboarding Completed', category: 'onboarding'},
    ONBOARDING_STEP_COMPLETED: {id: 'ONB003', name: 'Onboarding Step Completed', category: 'onboarding'}
};

export const NavigationEvents = {
    NAV_PROFILE_CLICK: {id: 'NAV001', name: 'Profile Navigation', category: 'navigation'},
    NAV_DASHBOARD_CLICK: {id: 'NAV002', name: 'Dashboard Navigation', category: 'navigation'},
    NAV_POSTS_CLICK: {id: 'NAV003', name: 'Posts Navigation', category: 'navigation'},
    NAV_TEMPLATES_CLICK: {id: 'NAV004', name: 'Templates Navigation', category: 'navigation'},
    NAV_SETTINGS_CLICK: {id: 'NAV005', name: 'Settings Navigation', category: 'navigation'},
    NAV_LOGIN_CLICK: {id: 'NAV005', name: 'Login Navigation', category: 'navigation'},
    NAV_SIGNUP_CLICK: {id: 'NAV005', name: 'Signup Navigation', category: 'navigation'},
    NAV_LOGOUT_CLICK: {id: 'NAV006', name: 'Logout Click', category: 'navigation'},
    MOBILE_MENU_OPEN: {id: 'NAV007', name: 'Mobile Menu Opened', category: 'navigation'},
    MOBILE_MENU_CLOSE: {id: 'NAV008', name: 'Mobile Menu Closed', category: 'navigation'}
};

export const ProfileEvents = {
    PROFILE_UPDATE_SUCCESS: {id: 'PROF001', name: 'Profile Update Success', category: 'profile'},
    PROFILE_BTN_CLICKED: {id: 'PROF001', name: 'Profile Button Click', category: 'profile'},
    PROFILE_UPDATE_FAILURE: {id: 'PROF002', name: 'Profile Update Failure', category: 'profile'},
    SOCIAL_CONNECTION_VIEW: {id: 'PROF003', name: 'Social Connections Viewed', category: 'profile'},
    BILLING_DETAILS_BTN_CLICKED: {id: 'PROF004', name: 'Billing Details Button Click', category: 'profile'}
};

export const ComposePostEvents = {
    POST_SUCCESS: {name: 'post success'},
    POST_FAILURE: {name: 'post failure'},
    SCHEDULE_POST_SUCCESS: {name: 'schedule post success'},
    SCHEDULE_POST_FAILURE: {name: 'schedule post failure'},
    MEDIA_UPLOAD_SUCCESS: {name: 'media upload success'},
    MEDIA_UPLOAD_FAILURE: {name: 'media upload failure'},
    SPELL_CHECK_TRIGGERED: {name: 'spell check triggered'},
    POST_NOW_CLICKED: {name: 'post now Button Click'},
    SCHEDULE_BUTTON_CLICKED: {name: 'schedule button Click'},
    POST_CLEAR_ALL_CLICKED: {name: 'Clear all text button Click'},
    UPLOAD_MEDIA_BTN_CLICKED: {name: 'Media Upload button Click'},
    GENERATE_MEDIA_BTN_CLICKED: {name: 'Generate Media Button Click'},
    ATTACH_MEDIA_BTN_CLICKED: {name: 'Attach Media button Click'},
    REMOVE_MEDIA_BTN_CLICKED: {name: 'Removed Attach Media button Click'},
    MOBILE_SELECT_SOCIAL_ACCOUNTS_BTN_CLICKED: {name: 'Select Account Dropdown Click'},
    SAME_TEXT_CHECKBOX: {name: 'Checked same text across all platforms'},
};

export const ImageGalleryEvents = {
    CLOSE_DIALOG_BTN_CLICKED: {name: 'Close Image Galley Dialog Button Click'},
    SELECT_TEMPLATE_BTN_CLICKED: {name: 'Selected Template Button Click'},
    SELECT_NEW_TEMPLATE_BTN_CLICKED: {name: 'Select New Template Button Click'},
    UPLOAD_VIDEO_BTN_CLICKED: {name: 'Upload Video Button Click'},
    GENERATE_NEW_BTN_CLICKED: {name: 'Generating New Media Button Click'},
    PREVIEW_VIDEO_BTN_CLICKED: {name: 'Preview Video Button Click'},
    ADD_NEW_MEDIA_BTN_CLICKED: {name: 'Add Media to Post Button Click'},
    EDIT_NEW_MEDIA_BTN_CLICKED: {name: 'Edit New Media Button Click'},
    PROCESSING_VIDEO_BTN_CLICKED: {name: 'Processing Video Button Click'},
    PROCESSING_VIDEO_CLIP_ERROR: {name: 'Processing Clip Video Error'},
    PROCESSING_VIDEO_CLIP_SUCCESS: {name: 'Processing Clip Video Success'},
    PROCESSING_MEDIA_POLLING_SUCCESSFUL: {name: 'Polling Media Success'},
    PROCESSING_MEDIA_POLLING_ERROR: {name: 'Polling Media Error'},
    GIF_RADIO_SELECTED: {name: 'Select Gif Radio Click'},
    GIF_VIDEO_SELECTED: {name: 'Select Video Radio Click'},
};

export const EditorEvents = {
    LOADED_TEMPLATE_SUCCESSFULLY: {name: 'Editor Loaded Pre Save Template Successfully'},
    LOADED_TEMPLATE_ERROR: {name: 'Editor Loaded Pre Save Template Error'},
    SAVED_TEMPLATE_SUCCESSFULLY: {name: 'Editor Save Template Successfully'},
    SAVED_TEMPLATE_ERROR: {name: 'Editor Save Template Error'},
    SAVED_TEMPLATE_BTN_CLICKED: {name: 'Editor Save Template Button Click'},
    NEW_PAGE_BTN_CLICKED: {name: 'EditorNew Page Button Click'},
    BRANDED_TEMPS_BTN_CLICKED: {name: 'Editor Brande Templates Button Click'},
}

export const TemplateCreateEvents = {
    CLOSE_DIALOG_BTN_CLICKED: {name: 'Close Image Galley Dialog Button Click [Template Create Page]'},
    DOWNLOAD_MEDIA_BTN_CLICKED: {name: 'Download Media Button Click [Template Create Page]'},
    SELECT_TEMPLATE_BTN_CLICKED: {name: 'Selected Template Button Click [Template Create Page]'},
    SELECT_NEW_TEMPLATE_BTN_CLICKED: {name: 'Select New Template Button Click [Template Create Page]'},
    UPLOAD_VIDEO_BTN_CLICKED: {name: 'Upload Video Button Click [Template Create Page]'},
    GENERATE_NEW_BTN_CLICKED: {name: 'Generating New Media Button Click [Template Create Page]'},
    PREVIEW_VIDEO_BTN_CLICKED: {name: 'Preview Video Button Click [Template Create Page]'},
    CREATE_NEW_MEDIA_BTN_CLICKED: {name: 'Add Media to Post Button Click [Template Create Page]'},
    EDIT_NEW_MEDIA_BTN_CLICKED: {name: 'Edit New Media Button Click [Template Create Page]'},
    PROCESSING_VIDEO_BTN_CLICKED: {name: 'Processing Video Button Click [Template Create Page]'},
    PROCESSING_VIDEO_CLIP_ERROR: {name: 'Processing Clip Video Error [Template Create Page]'},
    PROCESSING_VIDEO_CLIP_SUCCESS: {name: 'Processing Clip Video Success [Template Create Page]'},
    PROCESSING_MEDIA_POLLING_SUCCESSFUL: {name: 'Polling Media Success [Template Create Page]'},
    PROCESSING_MEDIA_POLLING_ERROR: {name: 'Polling Media Error [Template Create Page]'},
    GIF_RADIO_SELECTED: {name: 'Select Gif Radio Click [Template Create Page]'},
    GIF_VIDEO_SELECTED: {name: 'Select Video Radio Click [Template Create Page]'},
}