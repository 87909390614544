import React from 'react';
import { Alert, List, ListItem, ListItemText, Button, Box } from '@mui/material';
import styled from '@emotion/styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const SpellCheckBanner = ({ spellCheckResults, onConfirmChanges }) => {
  return (
    <StyledAlert severity="warning">
      <List dense>
        {Object.entries(spellCheckResults).map(([misspelled, suggestion], index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`"${misspelled}" → "${suggestion}"`}
            />
          </ListItem>
        ))}
      </List>
      <StyledBox>
        <Button
          variant="contained"
          color="success"
          size="small"
          startIcon={<CheckCircleIcon />}
          onClick={onConfirmChanges}
        >
          Confirm Changes
        </Button>
      </StyledBox>
    </StyledAlert>
  );
};

export default SpellCheckBanner; 