import React, { createContext, useContext, useCallback } from 'react';
import {trackEvent} from "../services/analytics/fathom";

const AnalyticsContext = createContext(null);

/**
 * Provider component for analytics context
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components
 */
export const AnalyticsProvider = ({ children }) => {
  const track = useCallback((eventId, options = {}) => {

        if (!eventId) {
            console.warn('No event ID provided for tracking');
            return;
        }

        try {
            window.fathom?.trackEvent(eventId.name, options.value || 0);

            // Optional: Log additional metadata for debugging
            if (process.env.NODE_ENV === 'development') {
                console.log('Tracked event:', {
                    eventId,
                    ...options
                });
            }
        } catch (error) {
            console.error('Failed to track event:', error);
        }
  }, []);

  return (
    <AnalyticsContext.Provider value={{ trackEvent: track }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

/**
 * Hook to use analytics tracking
 * @returns {Object} Analytics tracking methods
 */
export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};