import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Fathom site ID should come from environment variable
const FATHOM_SITE_ID = process.env.REACT_APP_FATHOM_SITE_ID;

/**
 * Custom error for analytics-related issues
 */
class AnalyticsError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AnalyticsError';
  }
}

/**
 * Analytics event categories
 * @readonly
 * @enum {string}
 */
export const EventCategories = {
  AUTH: 'auth',
  SOCIAL: 'social',
  CONTENT: 'content',
  SUBSCRIPTION: 'subscription',
  ERROR: 'error',
  USER_ACTION: 'user_action'
};

/**
 * Analytics events configuration
 * @readonly
 * @enum {Object}
 */
export const AnalyticsEvents = {
  // Authentication events
  LOGIN_SUCCESS: { name:"Login Success", id: 'ABC123', category: EventCategories.AUTH },
  LOGIN_FAILURE: { name:"Login Failure", id: 'ABC124', category: EventCategories.AUTH },
  SIGNUP_SUCCESS: { name:"Signup Success", id: 'ABC125', category: EventCategories.AUTH },
  SIGNUP_FAILURE: { name:"Signup Failure", id: 'ABC126', category: EventCategories.AUTH },

  // Social media events
  SOCIAL_CONNECT_SUCCESS: { name:"Social Connect Success", id: 'XYZ123', category: EventCategories.SOCIAL },
  SOCIAL_POST_SUCCESS: { name:"Social Post Success", id: 'XYZ124', category: EventCategories.SOCIAL },
  SOCIAL_POST_FAILURE: { name:"Social Post Failure", id: 'XYZ125', category: EventCategories.SOCIAL },

  // Content events
  TEMPLATE_CREATE: { name:"Template Create", id: 'DEF123', category: EventCategories.CONTENT },
  TEMPLATE_EDIT: { name:"Template Edit", id: 'DEF124', category: EventCategories.CONTENT },

  // Subscription events
  SUBSCRIPTION_START: { name:"Subscription Start", id: 'GHI123', category: EventCategories.SUBSCRIPTION },
  SUBSCRIPTION_CANCEL: { name:"Subscription Cancel", id: 'GHI124', category: EventCategories.SUBSCRIPTION },

  // Error events
  API_ERROR: { name:"Api Error", id: 'ERR123', category: EventCategories.ERROR },
  CLIENT_ERROR: { name:"Client Error", id: 'ERR124', category: EventCategories.ERROR }
};

/**
 * Tracks a custom event in Fathom
 * @param {string} eventId - The Fathom event ID
 * @param {Object} [options] - Additional tracking options
 * @param {number} [options.value] - Numerical value associated with the event
 * @throws {AnalyticsError} If tracking fails
 */
export const trackEvent = (eventId, options = {}) => {

  if (!window.fathom) {
    console.warn('Fathom not initialized');
    return;
  }

  try {
    window.fathom.trackEvent(eventId, options.value || 0);
  } catch (error) {
    console.error('Failed to track event:', error);
    throw new AnalyticsError(`Failed to track event: ${eventId}`);
  }
};

/**
 * Tracks an event from the predefined AnalyticsEvents
 * @param {Object} event - Event object from AnalyticsEvents
 * @param {Object} [options] - Additional tracking options
 */
export const trackAnalyticsEvent = (event, options = {}) => {
  if (!event || !event.id) {
    console.warn('Invalid event object provided');
    return;
  }

  trackEvent(event.id, options);
};

/**
 * React hook to track page views
 * @returns {void}
 */
export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.fathom) {
      window.fathom.trackPageview();
    }
  }, [location]);
};