import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
} from '@mui/material';
import { useAnalytics } from '../context/AnalyticsContext';
import { ButtonEvents } from '../services/analytics/events';
import WaitlistStatusCard from './WaitlistStatusCard';

const SOCIAL_PLATFORMS = [
  { id: 'twitter', label: 'Twitter' },
  { id: 'linkedin', label: 'LinkedIn' },
  { id: 'facebook', label: 'Facebook' },
  { id: 'bluesky', label: 'Bluesky' },
  { id: 'tiktok', label: 'TikTok' },
  { id: 'threads', label: 'Threads' },
  { id: 'mastodon', label: 'Mastodon' },
];

const WaitlistSignupCard = ({email}) => {
  const { trackEvent } = useAnalytics();
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [waitlistData, setWaitlistData] = useState(null);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: email || '',
    platforms: [],
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user types
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handlePlatformChange = (platform) => {
    setFormData(prev => ({
      ...prev,
      platforms: prev.platforms.includes(platform)
        ? prev.platforms.filter(p => p !== platform)
        : [...prev.platforms, platform]
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = 'First name is required';
    if (!formData.last_name) newErrors.last_name = 'Last name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/waitlist/signup/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        trackEvent(ButtonEvents.WAITLIST_SIGNUP_SUCCESS);
        setWaitlistData({
          priority: data.priority,
          referral_link: data.referral_link,
          tweet_url: data.tweet_url,
          uuid: data.uuid,
          amount_referred: 0
        });
        setSignupSuccess(true);
      } else {
        setErrors({ submit: data.message || 'Failed to join waitlist' });
      }
    } catch (error) {
      console.error('Signup error:', error);
      setErrors({ submit: 'Service temporarily unavailable' });
    }
  };

  // If signup was successful, show the WaitlistStatusCard
  if (signupSuccess && waitlistData) {
    return <WaitlistStatusCard waitlistData={waitlistData} />;
  }

  return (
    <Paper 
      elevation={3}
      sx={{
        maxWidth: 600,
        mx: 'auto',
        mt: 4,
        mb: 4,
        overflow: 'hidden',
        borderRadius: 2,
      }}
    >
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
            You're not on our waitlist
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center" color="primary">
            Join now to secure your waitlist spot now!
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
              <TextField
                required
                fullWidth
                id="first_name"
                name="first_name"
                label="First Name"
                value={formData.first_name}
                onChange={handleInputChange}
                error={!!errors.first_name}
                helperText={errors.first_name}
              />
              <TextField
                required
                fullWidth
                id="last_name"
                name="last_name"
                label="Last Name"
                value={formData.last_name}
                onChange={handleInputChange}
                error={!!errors.last_name}
                helperText={errors.last_name}
              />
            </Box>

            <TextField
              required
              fullWidth
              id="email"
              name="email"
              label="Email"
              type="email"
              value={email || formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ mb: 3 }}
            />

            <Typography variant="h6" gutterBottom>
              What are your top social media platforms?
            </Typography>

            <FormGroup sx={{ mb: 3 }}>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(150px, 1fr))', gap: 1 }}>
                {SOCIAL_PLATFORMS.map(platform => (
                  <FormControlLabel
                    key={platform.id}
                    control={
                      <Checkbox
                        checked={formData.platforms.includes(platform.id)}
                        onChange={() => handlePlatformChange(platform.id)}
                        name={platform.id}
                      />
                    }
                    label={platform.label}
                  />
                ))}
              </Box>
            </FormGroup>

            {errors.submit && (
              <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                {errors.submit}
              </Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mb: 2 }}
            >
              JOIN NOW
            </Button>

            <Typography variant="body2" color="text.secondary" align="center">
              By signing up you agree to the{' '}
              <Link href="https://www.onsolosocial.com/terms-of-service" target="_blank">
                terms of services
              </Link>
              {' '}&{' '}
              <Link href="https://www.onsolosocial.com/privacy-policy" target="_blank">
                privacy policy
              </Link>
              . We may send you some casual updates, but promise not to overdo it!
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default WaitlistSignupCard; 