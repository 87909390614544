import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Container, IconButton, InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {useStatus} from '../providers/MsgStatusProvider';
import {Visibility, VisibilityOff} from "@mui/icons-material";

// Styled components for password requirements
const RequirementsContainer = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
}));

const RequirementItem = styled(ListItem)(({theme}) => ({
    padding: theme.spacing(0.5),
    '& .MuiListItemIcon-root': {
        minWidth: '32px',
    },
}));

const RequirementText = styled(Typography)(({theme, isvalid}) => ({
    color: isvalid ? theme.palette.success.main : theme.palette.text.secondary,
    transition: 'color 0.3s ease',
}));

const PasswordResetConfirm = () => {
    const [formData, setFormData] = useState({
        new_password: '',
        confirm_password: '',
    });
    const [loading, setLoading] = useState(false);
    const [requirements, setRequirements] = useState({
        minLength: false,
        hasNumber: false,
        hasUpper: false,
        hasLower: false,
        hasSpecial: false,
        passwordsMatch: false,
    });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const {token} = useParams();
    const navigate = useNavigate();
    const {setStatusMessage, setIsAlertOpen, setStatusType} = useStatus();

    // Password requirements validation
    useEffect(() => {
        const {new_password, confirm_password} = formData;
        setRequirements({
            minLength: new_password.length >= 8,
            hasNumber: /\d/.test(new_password),
            hasUpper: /[A-Z]/.test(new_password),
            hasLower: /[a-z]/.test(new_password),
            hasSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(new_password),
            passwordsMatch: new_password === confirm_password && new_password !== '',
        });
    }, [formData]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const isFormValid = () => {
        return Object.values(requirements).every(Boolean);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    function handleHideShowPassword() {
        setIsPasswordVisible(!isPasswordVisible);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) return;

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}user/password/reset/confirm/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    ...formData,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setStatusType('success');
                setStatusMessage('Password has been reset successfully.');
                setIsAlertOpen(true);
                navigate('/login');
            } else {
                throw new Error(data.error || 'Failed to reset password');
            }
        } catch (error) {
            setStatusType('error');
            setStatusMessage(error.message);
            setIsAlertOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const renderRequirement = (text, isMet) => (
        <RequirementItem>
            <ListItemIcon>
                {isMet ? (
                    <CheckCircleIcon color="success"/>
                ) : (
                    <CancelIcon color="disabled"/>
                )}
            </ListItemIcon>
            <RequirementText variant="body2" isvalid={isMet}>
                {text}
            </RequirementText>
        </RequirementItem>
    );

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5" gutterBottom>
                    Set New Password
                </Typography>

                <Box component="form" onSubmit={handleSubmit} sx={{mt: 1, width: '100%'}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="new_password"
                        label="New Password"
                        type={isPasswordVisible ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleHideShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        id="new_password"
                        value={formData.new_password}
                        onChange={handleChange}
                        disabled={loading}
                        error={formData.new_password !== '' && !requirements.minLength}
                        aria-describedby="password-requirements"
                    />

                    <RequirementsContainer elevation={0}>
                        <Typography variant="subtitle2" gutterBottom>
                            Password Requirements:
                        </Typography>
                        <List dense>
                            {renderRequirement('At least 8 characters', requirements.minLength)}
                            {renderRequirement('Contains a number', requirements.hasNumber)}
                            {renderRequirement('Contains an uppercase letter', requirements.hasUpper)}
                            {renderRequirement('Contains a lowercase letter', requirements.hasLower)}
                            {renderRequirement('Contains a special character', requirements.hasSpecial)}
                            {renderRequirement('Passwords match', requirements.passwordsMatch)}
                        </List>
                    </RequirementsContainer>

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirm_password"
                        label="Confirm Password"
                        type={isPasswordVisible ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleHideShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        id="confirm_password"
                        value={formData.confirm_password}
                        onChange={handleChange}
                        disabled={loading}
                        error={formData.confirm_password !== '' && !requirements.passwordsMatch}
                        aria-describedby="password-match"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        disabled={loading || !isFormValid()}
                        aria-label="Set new password"
                    >
                        {loading ? <CircularProgress size={24}/> : 'Set New Password'}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default PasswordResetConfirm;