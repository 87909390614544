import React, {useEffect, useState} from 'react';
import {Alert, Box, FormControl, Grid, Stack, TextField, Typography} from '@mui/material';
import {useAuth} from "../providers/AuthProvider";
import {useFeatureAccess} from "../hooks/useFeatureAccess";
import SocialAccountsDisplay from "../components/SocialAccountsDisplay";
import {useAnalytics} from "../context/AnalyticsContext";
import {TrackedButton} from "../components/TrackedButton";
import {useTracking} from "../hooks/useTracking";
import {ProfileEvents} from '../services/analytics/events';
import {logInfo} from "../utils/logger";
import {useStatus} from "../providers/MsgStatusProvider";


export default function ProfilePage({user}) {
    const auth = useAuth();
    const {canAccessSocialConnections} = useFeatureAccess();
    const profile_url = process.env.REACT_APP_STRIPE_PPROFILE_URL
    const {trackEvent} = useAnalytics();
    const {trackWithError, trackWithSuccess} = useTracking();
    const {setStatusMessage, setIsAlertOpen, setStatusType} = useStatus();

    const user_details = auth.user[0];
    const isAccountActive = user_details?.userprofile?.subscription_status === 'active';
    const isAccountExpired = user_details?.userprofile?.subscription_status === 'expired';

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
    });
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (auth.user) {
            setFormData({
                first_name: auth.user[0]?.first_name,
                last_name: auth.user[0]?.last_name,
                email: auth.user[0]?.email,
            });
        }
    }, [auth.user]);

    const handleChange = event => {
        const {name, value} = event.target;
        setFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setFormData({
            first_name: auth.user[0].first_name,
            last_name: auth.user[0].last_name,
            email: auth.user[0].email,
        });
        setIsEditing(false);
    };

    async function updateProfile(formData) {
        try {
            const url = `${process.env.REACT_APP_API_BASE_URL}user/profile/update/`;
            const response = await fetch(url, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    first_name: formData.first_name?.trim(),
                    last_name: formData.last_name?.trim(),
                    email: formData.email?.trim()
                }),
            });

            // Check if response is ok before parsing JSON
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update profile');
            }

            const data = await response.json();

            // Log successful profile update for monitoring
            logInfo('Profile update successful', {
                userId: auth.user[0]?.id,
                timestamp: new Date().toISOString()
            });

            return {
                ok: true,
                data
            };

        } catch (error) {
            // Log error for debugging and monitoring
            logError('Profile update failed', {
                error: error.message,
                userId: auth.user[0]?.id,
                formData,
                timestamp: new Date().toISOString()
            });

            return {
                ok: false,
                error: error.message || 'An unexpected error occurred'
            };
        }
    }

    const handleSave = async () => {
        // send form data to backend API to update user profile
        setIsEditing(false);
        try {
            const response = await updateProfile(formData);

            if (response.ok) {
                trackWithSuccess(ProfileEvents.PROFILE_UPDATE_SUCCESS, {
                    userId: auth.user[0]?.id
                });
                setStatusMessage('Updated profile information successfully');
                setIsAlertOpen(true)
                setStatusType('success')
                setIsEditing(false);
            } else {
                setStatusMessage('We ran into an error updating your profile. Please try again.');
                setIsAlertOpen(true)
                setStatusType('error')
                setIsEditing(false);
                throw new Error('Failed to update profile');
            }
        } catch (error) {
            trackWithError(ProfileEvents.PROFILE_UPDATE_FAILURE, error);
        }
    };

    const handleBillingClick = () => {
        trackEvent(ProfileEvents.BILLING_DETAILS_VIEW, {
            userId: auth.user[0]?.id
        });
    };

    const renderSocialConnections = () => {
        if (!canAccessSocialConnections) {
            return (
                <Alert severity="info" sx={{mb: 2}}>
                    Social media connections are not available in your current plan.
                    Please upgrade to connect social media accounts.
                </Alert>
            );
        }

        return (
            <>
                <Grid item xs={12} md={4}>
                    <Typography variant="p">
                        Connect to social media accounts to post to different platforms
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    {['twitter', 'linkedin', 'linkedin_org', 'instagram', 'threads', 'bluesky', 'tiktok', 'mastodon', 'facebook'].map((platform) => (
                        <Grid item md={12} key={platform} sx={{
                            mb: {xs: 2, sm: 3},
                            px: {xs: 1, sm: 2}
                        }}>
                            <Typography
                                variant="p"
                                sx={{
                                    textTransform: 'capitalize',
                                }}
                            >
                                {platform}
                            </Typography>
                            <hr/>
                            <SocialAccountsDisplay filtered_account={platform}/>
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    };
    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (!auth.isLoading && auth.user) {

        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{mb: 1}}>
                            Account Details
                        </Typography>
                        <hr/>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Typography
                            variant="body1"
                            sx={{
                                mb: {xs: 2, md: 0},
                                color: 'text.secondary'
                            }}
                        >
                            Update your account details here
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <FormControl fullWidth>
                            <Stack p={2} spacing={3}>
                                <TextField
                                    label="First Name"
                                    variant="outlined"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    disabled
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                    helperText="Need to change your email address? Please contact support."
                                />

                                <Box sx={{
                                    mt: 2,
                                    display: 'flex',
                                    justifyContent: {xs: 'stretch', sm: 'flex-start'}
                                }}>
                                    <TrackedButton
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                        trackingData={ProfileEvents.PROFILE_BTN_CLICKED}
                                        sx={{
                                            width: {xs: '100%', sm: 'auto'},
                                            minWidth: {sm: '120px'}
                                        }}>
                                        Save
                                    </TrackedButton>
                                </Box>
                            </Stack>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">Billing Details</Typography>
                        <hr/>
                    </Grid>
                    <Grid p={2} item xs={8}>
                        <TrackedButton
                            variant="contained"
                            color={(!isAccountActive || isAccountExpired) ? "error" : "primary"}
                            onClick={handleSave}
                            href={profile_url}
                            trackingData={ProfileEvents.BILLING_DETAILS_BTN_CLICKED}
                            sx={{
                                width: {xs: '100%', sm: 'auto'},
                                minWidth: {sm: '120px'}
                            }}>
                            {(!isAccountActive || isAccountExpired) 
                                ? "Upgrade to Access Features" 
                                : "View your Payment Details"}
                        </TrackedButton>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Connected Accounts</Typography>
                            <hr/>
                        </Grid>
                        {/*<Box p={2}>   */}
                        {renderSocialConnections()}
                        {/*</Box>*/}
                    </Grid>
                </Grid>

            </div>
        );
    }
}