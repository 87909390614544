import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  IconButton,
  Tooltip,
  Paper,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { useAnalytics } from '../context/AnalyticsContext';
import { ButtonEvents } from '../services/analytics/events';

const WaitlistStatusCard = ({ waitlistData }) => {
  const { trackEvent } = useAnalytics();
  
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(waitlistData.referral_link);
      trackEvent(ButtonEvents.WAITLIST_LINK_COPIED);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Join me on SoloSocial',
          text: 'Skip the waitlist by using my referral link!',
          url: waitlistData.referral_link,
        });
        trackEvent(ButtonEvents.WAITLIST_LINK_SHARED);
      }
    } catch (err) {
      console.error('Share failed:', err);
    }
  };

  return (
    <Paper 
      elevation={3}
      sx={{
        maxWidth: 600,
        mx: 'auto',
        mt: 4,
        mb: 4,
        overflow: 'hidden',
        borderRadius: 2,
      }}
    >
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            color: 'primary.main'
          }}>
            🎉 You're on the Waitlist!
          </Typography>

          <Box sx={{ 
            display: 'flex',
            justifyContent: 'space-around',
            mb: 3,
            mt: 2,
            p: 2,
            bgColor: 'background.default',
            borderRadius: 1
          }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" color="primary">
                #{waitlistData.priority}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Your Position
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" color="primary">
                {waitlistData.amount_referred}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Referrals
              </Typography>
            </Box>
          </Box>

          <Typography variant="body1" sx={{ mb: 2 }}>
            Want to skip the line? Share your referral link and move up faster! Each referral moves you up in the queue.
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            mb: 2 
          }}>
            <TextField
              fullWidth
              variant="outlined"
              value={waitlistData.referral_link}
              InputProps={{
                readOnly: true,
              }}
              size="small"
              sx={{
                marginBottom: 0,
              }}
            />
            <Tooltip title="Copy link">
              <IconButton 
                onClick={handleCopyLink} 
                aria-label="copy referral link"
                color="primary"
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
            {navigator.share && (
              <Tooltip title="Share">
                <IconButton 
                  onClick={handleShare} 
                  aria-label="share referral link"
                  color="primary"
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            We'll email you as soon as your account is approved!
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default WaitlistStatusCard; 