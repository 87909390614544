import * as React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Box, StyledEngineProvider, ThemeProvider} from '@mui/material';
import './index.css'

import LoginForm from './pages/LoginPage';

import Footer from "./layout/Footer";
import SocialMediaPostDetailsPage from "./pages/SocialMediaPostDetailsPage";
import OnboardingFlowPage from "./pages/OnboardingFlowPage";
import ProfilePage from "./pages/ProfilePage";
import TopNavBar from "./layout/TopNavBar";
import Register from "./pages/Register";
import TemplateEditorPage from "./pages/TemplateEditorPage";
import DashboardPage from "./pages/DashboardPage";
import TemplateGeneratorPage from "./pages/TemplateGeneratorPage";
import SocialConnectionPage from "./pages/SocialConnectionPage";
import CheckEmailPage from "./pages/CheckEmailPage";
import {PrivateRoutes} from "./providers/PrivateRouteProvider";
import {useAuth} from "./providers/AuthProvider";

import PricingPage from "./pages/PricingPage";
import PaidPage from "./pages/PaidPage";
import StatusAlert from "./components/Alerts";
import NewPostPage from "./pages/NewPost";

import theme from './theme'
import styled from '@emotion/styled';
import ConfirmEmailPage from "./pages/ConfirmEmailPage";
import SocialConnectionLinkedInPage from "./pages/SocialConnectionLinkedInPage";
import SocialConnectionInstagramPage from "./pages/SocialConnectionInstagramPage";
import SocialConnectionTwitterPage from "./pages/SocialConnectionTwitterPage";
import SocialConnectionTikTok from "./pages/SocialConnectionTikTok";
import SocialConnectionThreads from "./pages/SocialConnectionThreads";
import SocialConnectionLinkedInOrgPage from "./pages/SocialConnectionLinkedInOrgPage";
import {FeatureGuard} from "./components/FeatureGuard";
import TemplateCreate from "./components/TemplateCreate";
import SocialConnectionMastodon from "./pages/SocialConnectionMastodon";
import SocialConnectionFacebook from "./pages/SocialConnectionFacebook";
import PasswordResetRequest from "./pages/PasswordResetRequest";
import PasswordResetConfirm from "./pages/PasswordResetConfirm";
import NotFoundPage from "./pages/NotFoundPage";
import {AnalyticsRouter} from "./components/AnalyticsRouter";
import {AnalyticsProvider} from "./context/AnalyticsContext";

const AppWrapper = styled.div`
    //display: flex;
    //flex-direction: column;
    //// flex: 1 0 auto;
    //min-height: 100vh;
    //width: 100%;
`;

const PageContainer = styled.div`
    //display: flex;
    //flex-direction: column;
    //flex: 1 0 auto;
    ////width: 100%;
    // height: 100%;
    margin-bottom: 24px;
    min-height: 100vh;
    height: auto;
    margin-top: 48px;
`;

const MainContent = styled.main`
    ////display: flex;
    ////flex-direction: column;
    //flex: 1 0 auto;
    ////width: 100%;
    padding: 24px;
    @media (max-width: 600px) {
        padding: 0;
    }
    ////min-height: 100%;
    ////display: flex;
`;

const FooterWrapper = styled.div`
    //flex-shrink: 0;
    ////width: 100%;
`;

export default function App() {
    const {user} = useAuth();

    return (<StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AnalyticsProvider>
                    <AnalyticsRouter/>
                    <AppWrapper id="appwrapper">
                        <TopNavBar id="topnav"/>
                        <PageContainer id="pageContainer">
                            <MainContent>
                                <StatusAlert/>
                                {/*<Box sx={{*/}
                                {/*    display: 'flex', flexDirection: 'column', flex: '1 0 auto', width: '100%'*/}
                                {/*}}*/}
                                <Box>
                                    <Routes>
                                        <Route exact path="/" element={<LoginForm/>}/>
                                        <Route exact path="/login" element={<LoginForm/>}/>
                                        <Route exact path="/signup" element={<Register/>}/>
                                        <Route exact path="/onboarding" element={<OnboardingFlowPage/>}/>
                                        <Route path="/forgot-password" element={<PasswordResetRequest/>}/>
                                        <Route path="/forgot-password/:token" element={<PasswordResetConfirm/>}/>
                                        <Route exact path="/pricing" element={<PricingPage/>}/>
                                        <Route exact path="/paid" element={<PaidPage/>}/>
                                        <Route exact path="/check-email" element={<CheckEmailPage/>}/>
                                        <Route exact path="/confirm-email/:token" element={<ConfirmEmailPage/>}/>
                                        {/*<Route element={<PrivateRoutes>}>*/}
                                        <Route exact path="/post/create" element={<PrivateRoutes
                                            userDetail={user?.[0]}>
                                            <FeatureGuard requiredPermission="canCreatePosts">
                                                <NewPostPage/>
                                            </FeatureGuard>
                                        </PrivateRoutes>}/>
                                        {/*<Route exact path="/testing/post" element={<PrivateRoutes*/}
                                        {/*    userDetail={user?.[0]}><SocialMediaPostsPage/></PrivateRoutes>}/>*/}
                                        <Route exact path="/editor/:tempId?"
                                               element={<PrivateRoutes userDetail={user?.[0]}>
                                                   <FeatureGuard
                                                       requiredPermission="canAccessTemplateEditor"
                                                       fallbackPath="/dashboard"
                                                       showError={true}>
                                                       <TemplateEditorPage/>
                                                   </FeatureGuard>
                                               </PrivateRoutes>

                                               }/>
                                        <Route exact path="/template/create/:templateId?"
                                               element={<PrivateRoutes userDetail={user?.[0]}>
                                                   <TemplateCreate
                                                       requiredPermission="canAccessTemplateEditor"
                                                       fallbackPath="/dashboard"
                                                       showError={true}>
                                                   </TemplateCreate>
                                               </PrivateRoutes>

                                               }/>
                                        <Route exact path="/post/:id" element={<PrivateRoutes
                                            userDetail={user?.[0]}><SocialMediaPostDetailsPage/></PrivateRoutes>}/>
                                        <Route exact path="/profile" element={<PrivateRoutes
                                            userDetail={user?.[0]}><ProfilePage/></PrivateRoutes>}/>
                                        <Route exact path="/dashboard" element={<PrivateRoutes
                                            userDetail={user?.[0]}><DashboardPage/></PrivateRoutes>}/>
                                        <Route exact path="/generate" element={<PrivateRoutes
                                            userDetail={user?.[0]}><TemplateGeneratorPage/></PrivateRoutes>}/>
                                        <Route exact path="/connect/socials" element={<PrivateRoutes
                                            userDetail={user?.[0]}><SocialConnectionPage/></PrivateRoutes>}/>
                                        <Route exact path="/connect/linkedin/callback" element={<PrivateRoutes
                                            userDetail={user?.[0]}><SocialConnectionLinkedInPage/></PrivateRoutes>}/>
                                        <Route exact path="/connect/ig/callback" element={<PrivateRoutes
                                            userDetail={user?.[0]}><SocialConnectionInstagramPage/></PrivateRoutes>}/>
                                        <Route exact path="/connect/twitter/callback" element={<PrivateRoutes
                                            userDetail={user?.[0]}><SocialConnectionTwitterPage/></PrivateRoutes>}/>
                                        <Route exact path="/connect/tiktok/callback" element={<PrivateRoutes
                                            userDetail={user?.[0]}><SocialConnectionTikTok/></PrivateRoutes>}/>
                                        <Route exact path="/connect/threads/callback" element={<PrivateRoutes
                                            userDetail={user?.[0]}><SocialConnectionThreads/></PrivateRoutes>}/>
                                        <Route
                                              path="/connect/linkedin/business/callback"
                                              element={<SocialConnectionLinkedInOrgPage />}/>
                                        {/*</Route>*/}
                                        <Route exact path="/connect/mastodon/callback" element={<PrivateRoutes
                                            userDetail={user?.[0]}><SocialConnectionMastodon/></PrivateRoutes>}/>
                                        <Route exact path="/connect/facebook/callback" element={<PrivateRoutes
                                            userDetail={user?.[0]}><SocialConnectionFacebook/></PrivateRoutes>}/>
                                        <Route path="*" element={<NotFoundPage/>}/>
                                    </Routes>
                                </Box>
                            </MainContent>
                        </PageContainer>
                        <FooterWrapper id="footerWrapper">
                            <Footer/>
                        </FooterWrapper>
                    </AppWrapper>
                </AnalyticsProvider>
            </BrowserRouter>
        </ThemeProvider>
    </StyledEngineProvider>);
}
