import React, { useEffect } from 'react';
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import { useStatus } from "../providers/MsgStatusProvider";

function SocialConnectionLinkedInPage() {
    const navigate = useNavigate();
    const { setStatusMessage, setIsAlertOpen, setStatusType } = useStatus();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const url = process.env.REACT_APP_API_BASE_URL + 'connect/linkedin/callback/';

        if (code) {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
                },
                body: JSON.stringify({ code: code })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.detail === "LinkedIn login successful!") {
                        // Handle success response
                        console.log('LinkedIn authentication successful');
                        window.location.href = process.env.REACT_APP_BASE_URL + 'profile'
                        setTimeout(() => {
                            navigate('/post/create');
                        }, 3000);
                    } else {
                        // Handle failure response
                        console.error('LinkedIn authentication failed');
                        setStatusMessage('LinkedIn authentication failed')
                        setIsAlertOpen(true)
                        setStatusType('error')
                    }
                })
                .catch((error) => {
                    console.error(error)
                    setStatusMessage('LinkedIn authentication failed')
                    setIsAlertOpen(true)
                    setStatusType('error')
                });
        }
    }, []);
    return (
        <>
            <Typography variant="h1">
                You're Connected to LinkedIn.
            </Typography>
            <Typography variant="h4">
                You will be redirected shortly.
            </Typography>
        </>
    );
}

export default SocialConnectionLinkedInPage;
