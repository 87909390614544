import React from 'react';
import styled from '@emotion/styled';
import {useAuth} from "../providers/AuthProvider";
import ComposePost from "../components/ComposePost";
import {Container} from "../base";
import Typography from "@mui/material/Typography";
import TwitterConnectButton from "../components/TwitterConnectButton";
import LinkedInConnectButton from "../components/LinkedInConnectButton";
import Grid from "@mui/material/Grid";
import ThreadsConnectButton from "../components/ThreadsConnectButton";
import BlueSkyConnectButton from "../components/BlueSkyConnectButton";
import TikTokConnectButton from "../components/TikTokConnectButton";

// const Container = styled.div`
//   display: flex;
//   flex-wrap: wrap;
// `;

const TextField = styled.input`
  margin-left: 8px;
  margin-right: 8px;
  // width: 200px; // Uncomment if needed
`;

const Button = styled.button`
  margin: 8px;
`;
const StyledComposePost = styled(ComposePost)`
  width: 70vw;
  display: flex;
  align-self: center;
  flex-direction: column;
`;

const MessageWrapper = styled('div')(({theme}) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey["80"],
    color: theme.palette.grey["100"],
    display: 'flex',
    borderRadius: theme.spacing(2),
    // height: '80vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

function NewPostPage() {
    const auth = useAuth();
    const platforms = auth.user[0].userprofile.social_accounts;

    const handleSubmit = async (event) => {
        event.preventDefault();
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        await auth.login(username, password, timezone);
    };

    return (
        <Container>
            {platforms.length <= 0 ? (<>
                <MessageWrapper>
                    <Typography variant="h3" align="center" mb={3}>
                        Ready to draft your first post?
                    </Typography>
                    <Typography variant="h5" align="center" mb={3}>
                        Connect to one of your social media accounts to start drafting a new post.
                    </Typography>
                    <Grid container display="flex" flexDirection="row" align="center" gap={3} justifyContent="center">
                        <TwitterConnectButton/>
                        <LinkedInConnectButton/>
                        <BlueSkyConnectButton />
                        <ThreadsConnectButton />
                        <TikTokConnectButton />
                        <TwitterConnectButton />
                    </Grid>
                </MessageWrapper>
            </>) : (
                <>
                    <Typography variant="h2" align="center" mb={3}>
                        Create a Post
                    </Typography>
                    <StyledComposePost/>
                </>
            )}
        </Container>

    );
}

export default NewPostPage;