import React, { useEffect } from 'react';
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import { useStatus } from "../providers/MsgStatusProvider";

function SocialConnectionLinkedInOrgPage() {
    const navigate = useNavigate();
    const { setStatusMessage, setIsAlertOpen, setStatusType } = useStatus();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const url = process.env.REACT_APP_API_BASE_URL + 'connect/linkedin/business/';

        if (code) {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ code: code })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status && data.message === "LinkedIn business accounts connected successfully") {
                        // Handle success
                        console.log('LinkedIn business account connection successful');
                        setStatusMessage('Successfully connected LinkedIn Organization account');
                        setStatusType('success');
                        setIsAlertOpen(true);

                        // Redirect to profile page
                        window.location.href = process.env.REACT_APP_BASE_URL + 'profile';

                        // Optional: Navigate to post creation after delay
                        setTimeout(() => {
                            navigate('/post/create');
                        }, 3000);
                    } else {
                        throw new Error(data.message || 'Failed to connect LinkedIn Organization');
                    }
                })
                .catch((error) => {
                    console.error('LinkedIn Organization connection error:', error);
                    setStatusMessage('Failed to connect LinkedIn Organization account');
                    setIsAlertOpen(true);
                    setStatusType('error');

                    // Redirect back to profile page on error
                    setTimeout(() => {
                        navigate('/profile');
                    }, 2000);
                });
        }
    }, [navigate, setStatusMessage, setIsAlertOpen, setStatusType]);

    return (
        <>
            <Typography variant="h1">
                Connecting LinkedIn Organization Account
            </Typography>
            <Typography variant="h4">
                Please wait while we complete the connection...
            </Typography>
        </>
    );
}

export default SocialConnectionLinkedInOrgPage;