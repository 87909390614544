import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

/**
 * Component that handles analytics page tracking
 * Must be used within Router context
 */
export const AnalyticsRouter = () => {
    const location = useLocation();

    // Track page views when location changes
    useEffect(() => {
        if (window.fathom) {
            window.fathom.trackPageview();
        }
    }, [location]);

    return null;
};