import React, {useEffect, useMemo, useState} from 'react';
import dayjs from 'dayjs'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, dayjsLocalizer} from 'react-big-calendar'
import {useAuth} from "../providers/AuthProvider";
import styled from "@emotion/styled";
import CardContent from "@mui/material/CardContent";
import {Link} from "react-router-dom";
import PostDetailsModal from "../components/PostDetailsModal";
import {useTheme} from "@mui/system";
import RenderSocialIcons from "../components/RenderSocialIcons";
import {useFeatureAccess} from "../hooks/useFeatureAccess";
import TemplateCard from "../components/TemplateCard";
import {Box, Button, Grid, Typography} from "@mui/material";

const localizer = dayjsLocalizer(dayjs);

const StyledCalendar = styled(Calendar)`
    .rbc-event {
        background-color: transparent;
        border: none;
    }

    .rbc-event-content {
        height: 100%;
    }

    .rbc-row-segment {
        padding: 2px;
    }
`;

const MessageWrapper = styled('div')(({theme}) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey["80"],
    color: theme.palette.grey["100"],
    display: 'flex',
    borderRadius: theme.spacing(2),
    // height: '80vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const CustomEventCard = styled('div')`
    display: flex;
    //width: 107%;
    //height: 100%;
    flex-direction: column;
    //overflow: hidden;
    box-shadow: none;
        // margin: ${props => props.theme.spacing(1)};
    border-radius: ${props => props.theme.spacing(2)};
    background-color: transparent;
`;


const CustomEventMedia = styled('div')`
    width: 100px;
    height: 100px;
    overflow: hidden;
`;

const CustomEventImage = styled('img')`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${props => props.theme.spacing(2)};
`;

const CustomEventVideo = styled('video')`
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: ${props => props.theme.spacing(2)};
`;

const CustomEventContent = styled(CardContent)`
    //height: 100px;
`;

const UpgradeWrapper = styled('div')(({theme}) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(5),
  backgroundColor: theme.palette.info.light,
  display: 'flex',
  borderRadius: theme.spacing(2),
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: theme.spacing(2)
}));

function DashboardPage() {
    // const classes = useStyles();
    const auth = useAuth();
    const theme = useTheme();
    const {
        canAccessTemplateEditor,
        canCreatePosts,
        canAccessSocialConnections
    } = useFeatureAccess();
    const [templates, setTemplates] = useState([]);
    const [post, setPosts] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const posts_cal_events = auth.user[0].posts_cal_events;
    const is_social_accounts_set = auth.user[0].userprofile.social_accounts.length > 0

    const user_details = auth.user[0];
    const isAccountActive = user_details?.userprofile?.subscription_status === 'active';
    const isAccountExpired = user_details?.userprofile?.subscription_status === 'expired';

    const newData = post?.map(event => ({
        ...event,
        'title': event.platform_details[0]?.platform_specific_text || event.post_text,
        id: event.id,
        start: new Date(event.created_at),
        end: new Date(event.platform_details[0]?.posted_at || event.created_at),
        is_posted: event.platform_details.every(detail => detail.is_posted)
    }));


    let newPull = false;
    useEffect(() => {
        if (canAccessTemplateEditor) {
            const url = process.env.REACT_APP_API_BASE_URL + 'templates/';
            fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
                    },
                }
            )
                .then(response => response.json())
                .then(data => {
                    setTemplates(data);
                })
                .catch(error => console.error(error));
        }
    }, [newPull, canAccessTemplateEditor]);

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + 'post/posts/';
        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
                },
            }
        )
            .then(response => response.json())
            .then(data => {
                setPosts(data);
            })
            .catch(error => console.error(error));
    }, [newPull]);

    // Define a custom event wrapper component
    const ColoredEventWrapper = ({children, event}) => {
        const renderBackgroundColor = () => {
            if (event.is_video) {
                return theme.palette.common.white;
            }
            return theme.palette.grey["80"];
        }
        return React.cloneElement(children, {
            style: {
                ...children.props.style,
                height: '100px',
                // marginBottom: theme.spacing(2),
                display: 'flex',
                flexDirection: 'column',
                color: 'black',
                backgroundColor: renderBackgroundColor(),
                padding: theme.spacing(1),
                border: `1px solid ${theme.palette.common.black}`,
                width: '100%',
            },
        });
    };

    const handleEventClick = (event) => {
        setSelectedPost(event);
        setModalOpen(true);
    };
    const handleEditPost = (post) => {
        // Implement the logic to edit the post
        setModalOpen(false);
    };

    const CustomEvent = ({event}) => {

        const renderMedia = () => {
            if (event.is_text_only) {
                return null;
            }

            if (event.is_video || event.is_gif) {
                return (
                    <CustomEventMedia>
                        <CustomEventVideo src={event.img_url}/>
                    </CustomEventMedia>
                );
            }

            return (
                <CustomEventMedia>
                    <CustomEventImage src={event.img_url} alt={event.title}/>
                </CustomEventMedia>
            );
        };

        return (
            <CustomEventCard m={0}>

                <CustomEventContent>
                    <Typography variant="body2" sx={{flexGrow: 1}}>
                        {event.platform_details[0]?.platform_specific_text || event.post_text}
                        <RenderSocialIcons post={event}/>
                    </Typography>

                </CustomEventContent>
                <Typography mt={2} variant="caption" color="text.secondary">

                </Typography>
            </CustomEventCard>
        );
    }


    const {components} = useMemo(
        () => ({
            components: {
                event: CustomEvent,
            },
        }),
        []
    );

    const getWelcomeMessage = () => {
        if (!canAccessTemplateEditor && !canCreatePosts) {
            return (
                <Typography variant="h5">
                    Your current plan doesn't include access to templates or posts.
                    Please <Link to="/pricing">upgrade your plan</Link> to access these features.
                </Typography>
            );
        }

        if (templates.length <= 0 && canAccessTemplateEditor) {
            return (
                <Typography variant="h5">
                    Create your first <Link to="/editor">template</Link> to get started with Solo Social
                </Typography>
            );
        }

        if (!is_social_accounts_set && canAccessSocialConnections) {
            return (
                <Typography variant="h5">
                    Head over to your <Link to="/profile">profile</Link> to connect
                    your social media platforms.
                </Typography>
            );
        }

        if (canCreatePosts) {
            return (
                <Typography variant="h5">
                    Create your first <Link to="/post/create">post</Link> to see
                    how Solo Social can save you time
                </Typography>
            );
        }

        return (
            <Typography variant="h5">
                Welcome to Solo Social! Contact support if you need help getting started.
            </Typography>
        );
    };

    const fetchPosts = async (targetDate) => {
            try {
                const url = new URL(`${process.env.REACT_APP_API_BASE_URL}post/posts/`);

                // Add target_date parameter if provided
                if (targetDate) {
                    url.searchParams.append('target_date', targetDate.toISOString().split('T')[0]);
                } else {
                    const today = new Date();
                    url.searchParams.append('target_date', today.toISOString().split('T')[0]);
                }

                const response = await fetch(url.toString(), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${localStorage.getItem('token')}`
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch posts');
                }

                const data = await response.json();
                setPosts(data);

            } catch (error) {
                console.error('Error fetching posts:', error);
                // Handle error appropriately
            }
        }
    ;
    // Use in calendar navigation
    const handleNavigate = (date) => {
        fetchPosts(date);
    };
    const renderUpgradeMessage = () => {
            return (
                <UpgradeWrapper>
                    <Typography variant="h4" component="h2" gutterBottom>
                        {isAccountExpired ? 'Your Subscription Has Expired' : 'Account Inactive'}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {isAccountExpired 
                            ? `Your subscription expired on ${new Date(subscriptionEndDate).toLocaleDateString()}.`
                            : 'Your account is currently inactive.'}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Upgrade your account to continue using Solo Social's features and manage your social media presence effectively.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/profile"
                        size="large"
                        sx={{ mt: 2 }}
                    >
                        Upgrade Now
                    </Button>
                </UpgradeWrapper>
            );
    };

    // if (isAccountExpired && isAccountExpired){
    //     return (
    //         <Box>
    //             <Typography variant="h1" component='h1' gutterBottom>
    //                 Welcome!
    //             </Typography>
    //             {renderUpgradeMessage()}
    //         </Box>
    //     )
    // }

    return (
        <Box>
            <Typography variant="h1" component='h1' gutterBottom>
                Welcome!
            </Typography>
            {!isAccountActive && isAccountExpired &&(
                renderUpgradeMessage()
            )}

            {isAccountActive && !isAccountExpired && (
                <Grid container spacing={3}>
                    {canCreatePosts && posts_cal_events?.length > 0 ? (
                        <>
                        <Grid item xs={12}>
                            <StyledCalendar
                                localizer={localizer}
                                onSelectEvent={handleEventClick}
                                events={newData}
                                onNavigate={handleNavigate}
                                components={{
                                    event: CustomEvent,
                                    eventWrapper: ColoredEventWrapper
                                }}
                                defaultView="month"
                                startAccessor="start"
                                defaultDate={new Date()}
                                endAccessor="end"
                                views={['month', 'week', 'day']}
                                style={{height: 900}}
                            />
                            <PostDetailsModal
                                open={modalOpen}
                                onClose={() => setModalOpen(false)}
                                post={selectedPost}
                                onEdit={handleEditPost}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Grid container display="flex" justifyContent="center">
                                {templates.length <= 0 && canAccessTemplateEditor ? (
                                    <MessageWrapper width="100%">
                                        {getWelcomeMessage()}
                                    </MessageWrapper>
                                ) : (
                                    <Grid container display="flex" direction="column">
                                        <Typography variant="h4" alignItems="left" component="h2" gutterBottom>
                                            Your Templates
                                        </Typography>
                                        <Grid container display="flex" justifyContent="center" gap={4}>
                                            {templates.map((template, index) => (
                                                <Grid key={index} item sm={6} md={3}>
                                                    <TemplateCard template={template}/>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
                </Grid>
            )}
        </Box>
    );
}

export default DashboardPage;
