import { useCallback } from 'react';
import { useAnalytics } from '../context/AnalyticsContext';

export const useTracking = () => {
  const { trackEvent } = useAnalytics();

  const trackWithError = useCallback((event, error) => {
    trackEvent(event, {
      error: error?.message || 'Unknown error',
      timestamp: new Date().toISOString()
    });
  }, [trackEvent]);

  const trackWithSuccess = useCallback((event, data = {}) => {
    trackEvent(event, {
      ...data,
      timestamp: new Date().toISOString()
    });
  }, [trackEvent]);

  return { trackWithError, trackWithSuccess };
}; 