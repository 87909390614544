import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';

const SpellCheckModal = ({ open, onClose, spellCheckResults, onFix, onPostAnyway }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Potential Spelling Issues Found</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          We found some potential spelling issues in your post:
        </Typography>
        <List>
          {Object.entries(spellCheckResults).map(([misspelled, suggestion], index) => (
            <ListItem key={index}>
              <ListItemText 
                primary={`"${misspelled}" might be misspelled`}
                secondary={`Suggested correction: "${suggestion}"`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onPostAnyway} color="secondary">
          Post Anyway
        </Button>
        <Button onClick={onFix} variant="contained" color="primary">
          Fix Issues
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SpellCheckModal; 