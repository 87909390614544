import React, { useEffect, useState } from "react";
import {useAuth} from "../providers/AuthProvider";

const PricingPlansPage = () => {
  const auth = useAuth();
  const user = auth.user[0];
  const [plans, setPlans] = useState([]);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  return (

  <stripe-pricing-table customer-email={user?.email || ''} pricing-table-id="prctbl_1OCOIkIX1FUp6F9lgu2JOA3z" publishable-key="pk_live_WKAyOqKWNlpXhmk9lOgASi1F00KKU8trhv"></stripe-pricing-table>
  );
};

export default PricingPlansPage;
