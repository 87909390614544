import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { Button, TextField, Typography, Box } from '@mui/material';
import { useAuth } from '../providers/AuthProvider';
import { useStatus } from '../providers/MsgStatusProvider';
import {logError} from "../utils/logger";
import { useAnalytics } from '../context/AnalyticsContext';
import { AnalyticsEvents } from '../services/analytics/events';

export default function SocialConnectionFacebook() {
    const navigate = useNavigate();
    const location = useLocation();
    const { setStatusMessage, setIsAlertOpen, setStatusType } = useStatus();
    const { trackEvent } = useAnalytics();
    const { auth } = useAuth();

    useEffect(() => {
        // Get code from URL parameters
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        const error = searchParams.get('error');
        const error_description = searchParams.get('error_description');

        // Handle errors from Facebook authorization
        if (error) {
            logError('Facebook authorization error', { error, error_description });
            setStatusMessage(error_description || 'Failed to connect to Facebook');
            setIsAlertOpen(true);
            setStatusType('error');
            navigate('/profile');
            return;
        }

        // Process successful authorization
        if (code) {
            const url = `${process.env.REACT_APP_API_BASE_URL}connect/facebook/callback/`;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ code })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.status) {
                        trackEvent(AnalyticsEvents.SOCIAL_CONNECT_SUCCESS, {
                            platform: 'facebook',
                            userId: auth.user?.[0]?.id
                        });
                        setStatusMessage("Successfully connected to Facebook");
                        setIsAlertOpen(true);
                        setStatusType('success');
                        setTimeout(() => navigate('/profile'), 2000);
                    } else {
                        throw new Error(data.message || 'Failed to connect Facebook account');
                    }
                })
                .catch((error) => {
                    trackEvent(AnalyticsEvents.SOCIAL_CONNECT_FAILURE, {
                        platform: 'facebook',
                        error: error.message
                    });
                    logError('Facebook callback processing error', error);
                    setStatusMessage('Failed to connect Facebook account. Please try again.');
                    setIsAlertOpen(true);
                    setStatusType('error');
                    navigate('/profile');
                });
        }
    }, [location, navigate, setIsAlertOpen, setStatusMessage, setStatusType, trackEvent, auth]);

    return (
        <div role="main" aria-live="polite">
            <Typography variant="h1" component="h1" gutterBottom>
                Connecting to Facebook
            </Typography>
            <Typography variant="h4" component="p" gutterBottom>
                Please wait while we complete your connection...
            </Typography>
        </div>
    );
}
